<template>
  <div>
    <v-btn color="green" class="" icon x-large @click="dialog = true"
      ><v-icon>mdi-plus-box</v-icon></v-btn
    >
    <v-dialog v-model="dialog" width="950">
      <v-card class="pa-4">
        <v-card-title> <h2>Add Unit</h2></v-card-title>
        <v-card-text>
          <div class="d-flex">
            <v-row>
              <v-col cols="4">
                <div class="mt-3">
                  <v-text-field
                    label="Search "
                    v-model="customer_search"
                  ></v-text-field>
                  <p>Account Name:</p>
                  <h1 class="green--text">
                    {{ selected_account.customer_name }}
                  </h1>
                </div>
              </v-col>

              <v-col cols="8">
                <v-data-table
                  :search="customer_search"
                  :items="customers"
                  :headers="customer_headers"
                  :options="{ itemsPerPage: 3 }"
                >
                  <template v-slot:item.action="{ item }">
                    <v-btn small color="primary" @click="select_account(item)"
                      >select</v-btn
                    >
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
          <v-divider class="mt-4 mb-4"></v-divider>
          <h2>Unit Information:</h2>
          <v-form ref="form">
            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="Unit Number"
                  prepend-icon="mdi-code-tags"
                  v-model="form.unit_number"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <v-select
                  prepend-icon="mdi-qrcode"
                  :items="unit_type"
                  label="Unit Type"
                  v-model="form.unit_type"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-select>

                <v-select
                  prepend-icon="mdi-qrcode"
                  :items="unit_capacity"
                  label="Unit Capacity"
                  v-model="form.unit_capacity"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-select>
                <v-text-field
                  label="Unit Capacity"
                  v-if="form.unit_capacity == 'Others'"
                ></v-text-field>
                <v-select
                  prepend-icon="mdi-qrcode"
                  :items="unit_orientation"
                  label="Unit Orientation"
                  v-model="form.unit_orientation"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-select>
                <v-text-field
                  label="Unit Orientation"
                  v-if="form.unit_orientation == 'Others'"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  prepend-icon="mdi-qrcode"
                  label="Brand"
                  v-model="form.brand"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-qrcode"
                  label="Unit Power Supply"
                  v-model="form.unit_power_supply"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-qrcode"
                  label="Ampheres"
                  v-model="form.ampheres"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-qrcode"
                  label="Refrigerant"
                  v-model="form.refrigerant"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  prepend-icon="mdi-qrcode"
                  label="Patch Number"
                  v-model="form.patch_number"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-qrcode"
                  label="Asset Number"
                  v-model="form.asset_number"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-qrcode"
                  label="Serial Number"
                  v-model="form.serial_number"
                  :rules="[(v) => !!v || 'Item is required']"
                ></v-text-field>
              </v-col>
              <!-- <v-col>
                <p>Functional Components</p>
                <div class="container d-flex mt-n6">
                  <v-checkbox
                    v-model="form.includes"
                    label="All"
                    value="All"
                  ></v-checkbox>
                  <v-checkbox
                    class="ml-2"
                    v-model="form.includes"
                    label="Keyboard"
                    value="Keyboard"
                  ></v-checkbox>
                  <v-checkbox
                    class="ml-2"
                    v-model="form.includes"
                    label="Track Pad"
                    value="Track Pad"
                  ></v-checkbox>
                  <v-checkbox
                    class="ml-2"
                    v-model="form.includes"
                    label="Speaker"
                    value="Speaker"
                  ></v-checkbox>
                  <v-checkbox
                    class="ml-2"
                    v-model="form.includes"
                    label="Wifi"
                    value="Wifi"
                  ></v-checkbox>
                  <v-checkbox
                    class="ml-2"
                    v-model="form.includes"
                    label="N/a"
                    value="N/a"
                  ></v-checkbox>
                </div>
                <p>With Accessories</p>
                <div class="container d-flex mt-n6">
                  <v-checkbox
                    v-model="form.accessories"
                    label="All"
                    value="All"
                  ></v-checkbox>
                  <v-checkbox
                    class="ml-2"
                    v-model="form.accessories"
                    label="Charger"
                    value="Charger"
                  ></v-checkbox>
                  <v-checkbox
                    class="ml-2"
                    v-model="form.accessories"
                    label="Bag"
                    value="Bag"
                  ></v-checkbox>
                  <v-checkbox
                    class="ml-2"
                    v-model="form.accessories"
                    label="Cords/Cables"
                    value="Cords/Cables"
                  ></v-checkbox>

                  <v-checkbox
                    class="ml-2"
                    v-model="form.accessories"
                    label="N/a"
                    value="N/a"
                  ></v-checkbox>
                </div>

                <p>Date Recieved</p>
                <input
                  type="date"
                  v-model="form.date_received"
                  style="color: "
                />

                <br />
              </v-col> -->
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="addUnit" class="" :loading="loading">Add</v-btn>
          <v-btn @click="dialog = false">Close</v-btn>

          <v-spacer></v-spacer>
          <p class="caption grey--text">addUnitModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  created() {},
  data() {
    return {
      enable_unit: false,
      customer_search: "",
      customer_headers: [
        { text: "Action", value: "action" },
        { text: "Account Name", value: "customer_name" },
        { text: "Date Created", value: "created_at" },
      ],
      dialog: false,
      loading: false,
      selected_account: {},
      unit_type: ["DX", "Chilled", "Water Cooled"],
      unit_capacity: ["3TR", "5TR", "7.5TR", "10TR", "Others"],
      unit_orientation: [
        "Ducted",
        "Ceiling Cassette",
        "Ceiling Suspended",
        "Floor Mounted",
        "Others",
      ],
      form: {
        customer_id: null,
        unit_model: null,
        unit_brand: null,
        unit_type: null,
        serial_no: null,
        date_received: null,
        issued_warranty: [],
        includes: [],
        accessories: [],
      },
    };
  },
  computed: {
    ...mapGetters({ customers: "customer/customers" }),
  },
  methods: {
    enable_unit_type() {
      this.enable_unit = true;
      console.log(this.enable_unit);
    },
    select_account(item) {
      this.selected_account = item;
      this.form.customer_id = item.id;
      return item;
    },
    close() {
      this.$emit("close");
    },
    ...mapActions({
      add_unit: "unit/add_unit",
      set_notification: "notification/set_notification",
    }),
    async addUnit() {
      let x = window.confirm("Are you sure you want to proceed?");
      if (x) {
        this.loading = true;
        await this.add_unit(this.form).then(() => {
          this.loading = false;
        });

        return this.set_notification({
          message: "You have successfully inserted a new unit!",
          type: "success",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
